/* eslint-disable react/require-default-props */
import React, {
  ReactElement,
} from 'react';
import {
  AspectRatio, Box, Flex, Heading, HStack, Stack, Text,
} from '@chakra-ui/react';
import Image from 'next/image';

type ItemCardProps = {
  image?: string
  imageGrayscale?: boolean
  ImageOverlay?: React.ReactNode;
}

/**
 *  Generic Card for listing an item on a list.
 */

const ItemCardTop = ({
                    image, imageGrayscale, ImageOverlay,
                  }: ItemCardProps): ReactElement => (
    <Box position="relative" width="100%">
      {imageGrayscale && <Flex backgroundColor={'black'} position={"absolute"}
                               top={0} bottom={0} left={0} right={0} zIndex={1}
                               opacity={'20%'}
      />}
      {image && (
        <AspectRatio ratio={16 / 9} overflow="hidden" filter={ imageGrayscale? 'grayscale(100)': null }>
          <Image src={image} layout="fill" objectFit="cover" />
        </AspectRatio>
      )}
      <Box position="absolute" top={0} bottom={0} left={0} right={0} p={2}>
        {ImageOverlay}
      </Box>
    </Box>
);

export default ItemCardTop;
