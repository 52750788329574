// eslint-disable-next-line no-use-before-define
import React, {
  ReactNode,
} from 'react';

import {
  Flex,
  Stack,
  Grid,
  Button,
  GridItem, VStack, HStack,
} from '@chakra-ui/react';
import Head from 'next/head';
import router from 'next/router';
import {
  IEvent, IEventTier, GetEventsDocument, IGetEventsQuery,
} from '../../lib/graphql';

import { initializeApollo } from '../../lib/apollo-client';
import BasketFooter from '../../components/basket/basketFooter';
import isMobile from '../../utils/isMobile';
import ItemCard from '../../components/item/itemCard';
import Hero from '../../components/hero/hero';
import formatMoney from '../../utils/money';
import ItemTags from '../../components/item/ItemTags';
import { getDateString, getTimeFromDate } from '../../utils/dateUtils';

const apolloClient = initializeApollo();

interface IEventQueryResponse {
  events: Array<Pick<IEvent, 'id' | 'name' | 'image' | 'shortDescription' | 'startDateTime' | 'endDateTime'>
    &
    {
      tiers: Pick<IEventTier, 'id' | 'name' | 'price' | 'availableSpaces' | 'maxSpaces'>[]
    }
    >
}

async function fetchEvents(name?: number):
  Promise<IEventQueryResponse> {
  const query = await apolloClient.query({
    query: GetEventsDocument,
    variables: { data: { name } },
  });
  const { getEvents }: IGetEventsQuery = query.data;
  const { events } = getEvents;

  return { events };
}

function EventsList({
  events,
}: IEventQueryResponse): ReactNode {
  function sortByPrice(a, b): number {
    if (a.price && b.price) {
      if (a.price < b.price) {
        return -1;
      }
      if (a.price > b.price) {
        return 1;
      }
    }
    return 0;
  }

  return (
    <Flex direction="column">
      <Head>
        <title>Event booking</title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Head>
      <Hero title="Events" image="images/events/hero-dancer-group.jpeg" />
      {/* <Flex > */}
      <Flex width="100%" direction="column" alignItems="center" justifyItems="center">
        <Flex
          id="classList"
          flexDirection="column"
          width="80%"
          maxW="600px"
          zIndex="1"
        >
          <Stack spacing={8}>
            { events
              && (
              <Grid
                gridTemplateColumns={['repeat(1,100%)']}
                gap={5}
              >
                { events.map((item) => {
                  const actionButton = <Button size="sm" w="100%" py={[2, 2, 'auto']} onClick={() => router.push(`/event/${item.id}`)} variant="solid" colorScheme="yellow" flex={1}>View Event</Button>;
                  const fromPrice = [...item.tiers].sort(sortByPrice).reduce(
                    (obj, tier) => {
                      const min = obj.min === 0 || tier.price < obj.min ? tier.price : obj.min;
                      const max = obj.max < min || tier.price > obj.max ? tier.price : obj.max;
                      return (
                        {
                          min,
                          max,
                        });
                    },
                    { min: 0, max: 0 },
                  );
                  const seatCount = item.tiers.reduce(
                    (count, tiers) => tiers.availableSpaces + count, 0,
                  );
                  const maxSpaces = item.tiers.reduce(
                    (spaces, tier) => (tier.maxSpaces === -1 ? -1 : tier.maxSpaces), 0,
                  );
                  const soldOut = seatCount === 0;
                  // eslint-disable-next-line no-nested-ternary
                  const availableText = soldOut ? 'Sold out' : maxSpaces > -1 ? ' ' : ' ';
                  const dates = `${getDateString(new Date(item.startDateTime))} ${getTimeFromDate(new Date(item.startDateTime))}-${getTimeFromDate(new Date(item.endDateTime))}`;

                  return (
                    <GridItem key={`bookingList${item.id}`}>
                      <ItemCard
                        title={item.name}
                        subtitle={dates}
                        description={item.shortDescription}
                        image={item.image}
                        imageGrayscale={soldOut}
                        ActionButton={actionButton}
                        ImageOverlay={(
                          <VStack height="100%" alignItems="baseline" flexDirection="column">
                            <HStack zIndex={2}>
                              <ItemTags.CourseTypeBadge size="md" title={soldOut ? 'Sold out' : 'Available'} />
                            </HStack>
                          </VStack>
                        )}
                        prePriceText={!soldOut && availableText}
                        price={`From: ${formatMoney(fromPrice.min)}`}
                        topbar="PERFORMANCE"
                        topBarColor="yellow.600"
                      />
                    </GridItem>
                  );
                })}
              </Grid>
              )}
          </Stack>
        </Flex>
      </Flex>
      {/* </Flex> */}
      { isMobile() && <BasketFooter />}
    </Flex>
  );
}

EventsList.getInitialProps = async () => {
  const { events } = await fetchEvents();

  return {
    events,
  };
};

export default EventsList;
