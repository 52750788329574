import { Badge } from '@chakra-ui/react';
import React, { ReactElement } from 'react';
import {
  IAttendanceType,
} from '../../lib/graphql';

type CourseBadgeProps = {
  type?: string
  title?: string
  size: 'lg'|'md'
}

const CourseTypeBadge = ({ type, size, title }: CourseBadgeProps): ReactElement => {
  if (title) return <Badge mt="auto" size={size}>{title}</Badge>;
  switch (type) {
    case 'OnlineCourse':
      return <Badge mt="auto" size={size}>Online</Badge>;
      break;
    default:
      return <Badge mt="auto" size={size}>Studio</Badge>;
      break;
  }
};

type AttendanceTypeBadgeProps = {
  type: IAttendanceType
  size: 'lg'|'md'
}

const AttendanceTypeTag = ({ type, size }: AttendanceTypeBadgeProps) => {
  switch (type) {
    case 'CHILDREN':
      return <Badge mt="auto" colorScheme="pink" size={size}>Children</Badge>;
    case 'ADULTS':
    default:
      return <Badge mt="auto" colorScheme="yellow" size={size}>Adults</Badge>;
  }
};

const ItemTags = { AttendanceTypeTag, CourseTypeBadge };

export default ItemTags;
