/* eslint-disable react/require-default-props */
import React, {
  ReactElement,
} from 'react';
import {
  AspectRatio, Box, Flex, Heading, HStack, Stack, Text,
} from '@chakra-ui/react';
import Image from 'next/image';
import ItemCardTop from "./itemCardTop";

type ItemCardProps = {
    topbar?: string
    topBarColor?: string
    image?: string
    imageGrayscale?: boolean
    title: string
    subtitle?: string
    description?: string|React.ReactNode
    ImageOverlay?: React.ReactNode;
    price?: number|string
    prePriceText?: string
    ActionButton?: React.ReactNode;
}

/**
 *  Generic Card for listing an item on a list.
 */

const ItemCard = ({
  image, imageGrayscale, topbar, topBarColor = 'violet.300', title, description = '', price, prePriceText, ImageOverlay, ActionButton, subtitle
}: ItemCardProps): ReactElement => (
  <Flex direction="column" boxShadow="6px 6px 12px rgb(0 0 0 / 17%)" height="100%">
    {topbar && (
    <Box
      alignSelf="flex-end"
      bgColor={topBarColor}
      color="gray.700"
      px={2}
      pt={1}
      textTransform="capitalize"
      fontWeight="bold"
      letterSpacing="0.2px"
    >
      {topbar}
    </Box>
    )}
    <Stack direction="column" height="100%" bgColor="#fff" borderTopWidth="4px" borderColor={topBarColor}>
      <ItemCardTop image={image} ImageOverlay={ImageOverlay} imageGrayscale={imageGrayscale}/>
      <Stack direction="column" p={2} flexGrow={1}>
        <Box>
          <Heading as="h4" size="md" mt={image ? 0: 10}>
            {title}
          </Heading>
          {subtitle && <Heading as="h5" size={'sm'}>{subtitle}</Heading>}
          {description && <Text>{description}</Text>}
        </Box>
        <Stack direction={['column','row', 'row']} justifyContent="space-between" alignItems="flex-end" height="100%" flexGrow={1}>
          <HStack flexGrow={1} justifyContent={'space-between'}>
            <Text pb={'2px'}>{prePriceText}</Text>
            <Text fontWeight="bold" textAlign="right" pb="2px">{price}</Text>
          </HStack>
          { ActionButton && <Box width={['100%','auto', 'auto']}>{ActionButton}</Box>}
        </Stack>
      </Stack>
    </Stack>
  </Flex>
);

export default ItemCard;
