import React, { ReactNode } from 'react';
import { Flex, Heading } from '@chakra-ui/react';
/* eslint-disable react/require-default-props */

interface IHeroProps {
  image?: string,
  title: string,
  subtitle?: string,
  children?: ReactNode,
}
function Hero({
  image, title, subtitle, children,
}: IHeroProps) {
  const colour = image ? 'white' : 'blue.900';
  return (
    <Flex
      backgroundImage={`url('${image}')`}
      backgroundSize="cover"
      backgroundPosition="center"
      alignContent="center"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      width="100vw"
      my={10}
      position="relative"
      minH={[image ? '300px' : null]}
    >
      {image && (
      <Flex
        backgroundColor="gray.900"
        width="100%"
        alignItems="center"
        justifyItems="center"
        opacity="50%"
        position="absolute"
        top={0}
        bottom={0}
        left={0}
        right={0}
        zIndex={1}
      />
      )}
      <Flex zIndex={2} alignItems="center" justifyItems="center" flex={1} flexDirection="row" height="100%">
        <Flex flexDirection="column" alignItems="center">
          <Heading as="h1" size="3xl" m={6} color={colour}>
            { title }
          </Heading>
          { subtitle && <Heading as="h2" size="lg" color={colour}>{subtitle}</Heading>}
          { children }
        </Flex>
      </Flex>
    </Flex>
  );
}

export default Hero;
